/**
 * Combined by jsDelivr.
 * Original files:
 * - /gh/necolas/normalize.css@8.0.1/normalize.min.css
 * - /npm/animate.css@3.7.2/animate.min.css
 * - /npm/jquery-tooltipster@3.3.0/css/tooltipster.min.css
 * - /gh/malihu/malihu-custom-scrollbar-plugin@3.1.5/jquery.mCustomScrollbar.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
